import { graphql, navigate } from "gatsby";
import PropTypes from "prop-types";
import React from "react";

import FilteredCases from "../components/FilteredCases";
import SEO from "../components/SEO";
import { CASES_PATH } from "../constants";
import { caseItem, productBasic } from "../types";
import createLink from "../utils/createLink";
import updateURIParams from "../utils/updateURIParams";

const productToFilter = (product) => ({
  id: product.id,
  label: product.title,
  slug: product.slug,
  description: product.description,
});

/**
 * Checks wether a filter is active by url param.
 * @param slug
 * @param search
 * @returns {boolean}
 */
function filterIsActive(slug, search = "") {
  return slug === new URLSearchParams(search).get("product");
}

const Work = ({
  data: {
    data: { allCases, allProducts: _allProducts },
  },
  location,
}) => {
  const filters = _allProducts
    .filter(({ isCasesFilter }) => isCasesFilter)
    .map(productToFilter);
  const activeFilter = filters.find(({ slug }) =>
    filterIsActive(slug, location.search)
  );

  const formattedCases = allCases.map(
    ({
      id,
      customer,
      title,
      overviewTitle,
      description,
      slug,
      thumbnail,
      thumbnailVideo,
      domain,
      highlighted,
      products,
    }) => ({
      id,
      client: customer.name,
      title: overviewTitle || title,
      text: description,
      image: {
        data: highlighted ? thumbnail.highlight : thumbnail.small,
      },
      video: thumbnailVideo,
      domain,
      product: products[0]?.title,
      filters: products.map(productToFilter),
      highlighted,
      as: createLink(`${CASES_PATH}/${slug}`),
      slug,
    })
  );

  return (
    <>
      <SEO
        tags={[
          {
            tag: "title",
            content: "Werk | Greenberry",
          },
          {
            tag: "meta",
            attributes: {
              name: "description",
              content:
                "Met ontwerptrajecten en betekenisvolle (digitale) producten helpen wij verbeteraars impact maken. Bekijk het resultaat van onze werkwijze.",
            },
          },
        ]}
      />

      <FilteredCases
        title="Ons werk"
        filters={filters}
        activeFilter={activeFilter}
        cases={formattedCases}
        onChange={({ slug }) => {
          const newSearch = slug
            ? updateURIParams(location.search, "product", slug)
            : "";
          navigate(`/werk/${newSearch}`);
        }}
        noItemsText="Geen cases gevonden."
      />
    </>
  );
};

Work.propTypes = {
  data: PropTypes.shape({
    data: PropTypes.shape({
      allCases: PropTypes.arrayOf(PropTypes.shape(caseItem)).isRequired,
      allProducts: PropTypes.arrayOf(PropTypes.shape(productBasic)).isRequired,
    }).isRequired,
  }),
  location: PropTypes.shape({
    href: PropTypes.string.isRequired,
    search: PropTypes.string.isRequired,
  }).isRequired,
};

export default Work;

export const query = graphql`
  query CasesQuery {
    data: dato {
      allCases(
        orderBy: position_ASC
        first: "100"
        filter: { archive: { eq: false } }
      ) {
        id
        title
        overviewTitle
        slug
        thumbnail {
          highlight: responsiveImage(
            imgixParams: { fit: crop, w: 1068, h: 1068, auto: format }
          ) {
            ...responsiveImageFragment
          }
          small: responsiveImage(
            imgixParams: { fit: crop, w: 550, h: 550, auto: format }
          ) {
            ...responsiveImageFragment
          }
        }
        thumbnailVideo {
          ...MuxVideoFragment
        }
        customer {
          name
        }
        description
        domain {
          title
        }
        products {
          id
          slug
          title
          description
        }
        highlighted
        position
      }
      allProducts(orderBy: position_ASC) {
        id
        slug
        title
        description
        isCasesFilter
      }
    }
  }
`;
