const updateURIParams = (uri, key, value) => {
  const regex = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
  const separator = uri.indexOf("?") !== -1 ? "&" : "?";
  const _value = encodeURIComponent(value);

  if (uri.match(regex)) {
    return uri.replace(regex, "$1" + key + "=" + _value + "$2");
  }

  return uri + separator + key + "=" + _value;
};

export default updateURIParams;
