import styled from "@emotion/styled";
import { motion, useAnimation } from "framer-motion";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import regeneratorRuntime from "regenerator-runtime"; //eslint-disable-line

import theme from "../../theme";
import usePrevious from "../../utils/hooks/usePrevious";
import useItemFilter, { configType } from "../../utils/itemFilter";
import mq from "../../utils/mediaQuery";
import CaseItem, { caseItem } from "../CaseItem/v2";
import ContentWrapper from "../ContentWrapper";
import { filterItem } from "../Filter";
import Hero from "../Hero/v8";
import { GRID_CASE_ITEM_HEIGHT } from "./constants";

const Wrapper = styled.div`
  min-height: ${GRID_CASE_ITEM_HEIGHT}px;
`;

const ItemWrapper = styled(ContentWrapper)`
  ${mq("3")} {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 100px;
    > * {
      flex: 0 0 50%;
    }
  }
`;

const StyledCaseItem = styled(CaseItem)`
  margin: ${theme.space("xl")} 0;

  ${mq("3")} {
    margin: 0;
  }
`;

const StyledHighlightItem = styled(CaseItem)`
  margin: ${theme.space("xxl")} 0;
`;

const CaseItemWrapper = styled(motion.div)`
  ${mq("3")} {
    min-height: ${GRID_CASE_ITEM_HEIGHT}px;

    &:nth-of-type(odd) {
      padding-right: ${theme.space("l")};
    }

    &:nth-of-type(even) {
      padding-left: ${theme.space("l")};
      transform: translateY(100px);
    }
  }
`;

const NoItemsWrapper = styled.div`
  text-align: center;
`;

function FilteredCases({
  title,
  cases,
  filters,
  activeFilter,
  highlights,
  itemAmount,
  itemIncrement,
  moreButtonText,
  filterAllLabel,
  noItemsText,
  onChange,
  ...props
}) {
  const itemFilter = useItemFilter(
    cases,
    filters,
    activeFilter,
    onChange,
    highlights,
    {
      itemAmount,
      itemIncrement,
      moreButtonText,
      filterAllLabel,
      noItemsText,
    }
  );

  const prevFilter = usePrevious(itemFilter);
  const controls = useAnimation();

  useEffect(() => {
    const sequence = async () => {
      await controls.start("start");
      controls.start("end");
    };

    prevFilter && itemFilter.filter === prevFilter.filter
      ? controls.start("end")
      : sequence();
  }, [itemFilter, prevFilter, controls]);

  const containerMotion = {
    start: theme.get("animation.immediate"),
    end: theme.get("animation.immediate"),
  };

  const itemMotion = {
    start: {
      opacity: 0,
      marginTop: 60,
      transition: {
        duration: 0,
      },
    },
    end: {
      marginTop: 0,
      opacity: 1,
    },
    new: {
      marginTop: 0,
      opacity: 1,
    },
  };

  const highlightMotion = {
    start: {
      opacity: 0,
      y: 60,
      transition: {
        duration: 0,
      },
    },
    end: {
      y: 0,
      opacity: 1,
    },
    new: {
      y: 0,
      opacity: 1,
    },
  };

  return (
    <Wrapper {...props}>
      <Hero title={title} />

      <ContentWrapper columns={12}>{itemFilter.filterElement}</ContentWrapper>

      <motion.div initial="start" animate={controls} variants={containerMotion}>
        {itemFilter.highlighted && (
          <ContentWrapper columns={12}>
            {itemFilter.highlighted.map(({ id, ...caseItem }) => (
              <motion.div key={id} variants={highlightMotion}>
                <StyledHighlightItem
                  highlighted={true}
                  key={id}
                  {...caseItem}
                />
              </motion.div>
            ))}
          </ContentWrapper>
        )}

        <div
          style={{
            width: "100%",
            padding: `${theme.spacing("xl")} 0`,
            backgroundColor: `${theme.color("blue.light")}`,
          }}
        >
          <ItemWrapper>
            {itemFilter.items.map(({ id, ...caseItem }) => {
              if (activeFilter) {
                caseItem.highlighted = false;
              }
              return (
                <CaseItemWrapper key={id} variants={itemMotion}>
                  <StyledCaseItem {...caseItem} />
                </CaseItemWrapper>
              );
            })}

            {itemFilter.noItems && (
              <NoItemsWrapper>{itemFilter.noItems}</NoItemsWrapper>
            )}
          </ItemWrapper>

          {itemFilter.moreElement}
        </div>
      </motion.div>
    </Wrapper>
  );
}

FilteredCases.propTypes = {
  title: PropTypes.string,
  filters: PropTypes.arrayOf(PropTypes.shape(filterItem)),
  activeFilter: PropTypes.shape(filterItem),
  cases: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      ...caseItem,
    })
  ),
  highlights: PropTypes.bool,
  ...configType,
};

FilteredCases.defaultProps = {
  cases: [],
  highlights: true,
};

export default FilteredCases;
